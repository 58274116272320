<script lang="ts" setup>
import LabelText from '~/components/form-elements/LabelText.vue'

const modelValue = defineModel<string | number | null>()

const props = withDefaults(
  defineProps<{
    description?: string | null
    exampleText?: string | null
    isRequired?: boolean
    labelText?: string | null
    maxLength?: number
    placeholder?: string
  }>(),
  {
    description: null,
    exampleText: null,
    isRequired: false,
    labelText: null,
    maxLength: undefined,
    placeholder: undefined
  }
)
</script>

<template>
  <div>
    <LabelText class="mb-1" :is-required="isRequired" :value="labelText" />

    <textarea
      v-model="modelValue"
      autocomplete="off"
      class="bg-white"
      :maxlength="maxLength"
      :placeholder="placeholder"
    />

    <div v-if="description" class="description">
      {{ description }}
    </div>

    <Example v-if="exampleText" class="example" :text="exampleText" />
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/css/variables';

textarea {
  appearance: none;
  border: 1px solid mix($main-gray-color, white, 60%);
  border-radius: 12px;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 18px;
  height: 120px;
  line-height: 1.1em;
  outline: none;
  padding: 16px;
  resize: none;
  transition: border-color .2s ease-in-out, box-shadow .2s ease-in-out;
  width: 100%;

  &:focus {
    border-color: $main-green-color;
  }
}

.description {
  font-size: 14px;
  line-height: 1.1em;
  margin-top: 4px;
}

.example {
  margin-top: 4px;
}
</style>
